import api from '@/services/api'

export default {
  state: {
    isLoggedInDashFinancial: false,
    user: {
      codUsuario: null,
      cpf: null,
      email: null,
      localPhoto: null,
      name: null,
    },
  },
  mutations: {
    AUTH(state, user) {
      state.isLoggedInDashFinancial = true
      state.user = {
        codUsuario: user.codUsuario,
        cpf: user.cpf,
        email: user.email,
        localPhoto: user.localPhoto,
        name: user.name,
      }
    },

    LOGOUT(state) {
      state.isLoggedInDashFinancial = false
      state.user = {
        codUsuario: null,
        cpf: null,
        email: null,
        localPhoto: null,
        name: null,
      }
    },
  },
  actions: {
    async auth({ commit }, credentials) {
      try {
        const response = await api.post('/auth/create-token-cpf', credentials)
        const data = response && response.data ? response.data : null

        const token = data && data.token ? data.token : ''
        const user = {
          name: data && data.user ? data.user.name : '',
          codUsuario: data && data.user ? data.user.codUsuario : '',
          cpf: data && data.user ? data.user.cpf : '',
          email: data && data.user ? data.user.email : '',
          localPhoto: data && data.user ? data.user.localPhoto : '',
        }
        localStorage.setItem('tokenDashFinancial', token)
        commit('AUTH', user)
      } catch (error) {
        commit('LOGOUT')
        throw error
      }
    },
    logout: ({ commit }) => {
      commit('LOGOUT')
    },
  },
  getters: {
    isLoggedInDashFinancial: (state) => state.isLoggedInDashFinancial,
    user: (state) => state.user ?? null,
  },
}
