import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async getCompanies({ commit }, data) {
      try {
        const url = `/dash-financial/companies`
        const response = await api.get(url)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async getFilials({ commit }, data) {
      try {
        const url = `/dash-financial/companies/${data}/filials`
        const response = await api.get(url)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async getStatements({ commit }, data) {
      try {
        const url = `/dash-financial/statements`
        const response = await api.get(url, {
          params: {
            codColigada: data.codColigada,
            codFilial: data.codFilial,
            startDate: data.startDate,
            endDate: data.endDate,
          },
        })
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async getBalance({ commit }, data) {
      try {
        const url = `/dash-financial/balance`
        const response = await api.get(url, {
          params: {
            codColigada: data.codColigada,
            codFilial: data.codFilial,
            startDate: data.startDate,
          },
        })
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async getClassifications({ commit }, data) {
      try {
        const url = `/dash-financial/classifications`
        const response = await api.get(url, {
          params: {
            codColigada: data.codColigada,
          },
        })
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async saveSimulation({ commit }, data) {
      try {
        const url = `/dash-financial/simulations`
        const response = await api.post(url, data)
        return response
      } catch (error) {
        throw error
      }
    },
    async deleteSimulation({ commit }, data) {
      try {
        const url = `/dash-financial/simulations/${data.id}`
        const response = await api.delete(url)
        return response
      } catch (error) {
        throw error
      }
    },
  },
}
